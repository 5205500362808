const fromCharCode = String.fromCharCode;

export const BOM = '\xfe\xff';

// Encodes a string as UTF-8
export function encodeUTF8(input) {
    let output = "";

    for (let i = 0; i < input.length; i++) {
        const c = input.charCodeAt(i);

        if (c < 0x80) {
            // One byte
            output += fromCharCode(c);
        } else if (c < 0x800) {
            // Two bytes
            output += fromCharCode(0xC0 | (c >>> 6));
            output += fromCharCode(0x80 | (c & 0x3f));
        } else if (c < 0x10000) {
            // Three bytes
            output += fromCharCode(0xE0 | (c >>> 12));
            output += fromCharCode(0x80 | (c >>> 6 & 0x3f));
            output += fromCharCode(0x80 | (c & 0x3f));
        }
    }

    return output;
}

function encodeUnit(codeUnit) {
    return fromCharCode(codeUnit >> 8) + fromCharCode(codeUnit & 0x00ff);
}

// Encodes a string as UTF-16 big-endian
export function encodeUTF16BE(input) {
    let output = '';

    for (let i = 0; i < input.length; i++) {
        const c = input.charCodeAt(i);

        if (c < 0xFFFF) {
            output += encodeUnit(c);
        } else {
            const lead = ((c - 0x10000) >> 10) + 0xD800;
            const trail = ((c - 0x10000) & 0x3FF) + 0xDC00;
            output += encodeUnit(lead);
            output += encodeUnit(trail);
        }
    }

    return output;
}
